import { Component, OnInit, OnDestroy, OnChanges, Input, SimpleChanges, ComponentRef } from "@angular/core";
import { BehaviorSubject, Subscription, interval } from "rxjs";

import { SharedService } from "../../../../services/shared.service";

import { ReportsService } from "../../reports.service";
import { Report, Run } from "../../report";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { KeyMap } from "src/app/models/shared";
import { TranslateService } from "@ngx-translate/core";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxDateTimeDisplayComponent } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.component";
import { assignDateTimeDisplayInputsFactory } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.table-adapter";
import { TimezonePipe } from "src/app/pipes/timezone.pipe";
import { DecimalPipe, TitleCasePipe } from "@angular/common";
import { ReportHistoryActionsColumnComponent } from "./report-history-actions-column/report-history-actions-column.component";

@Component({
    selector: "app-report-history",
    templateUrl: "./report-history.component.html",
    providers: [TimezonePipe, TitleCasePipe]
})
export class ReportHistoryComponent implements OnInit, OnDestroy, OnChanges {
    @Input() report: Report;
    @Input() canEdit: boolean;
    @Input() bordered? = false;
    @Input() autoRows? = true;

    runs: Run[];
    loading: boolean;
    activeTab: string;

    private runsBS$ = new BehaviorSubject<Run[]>([]);
    private runsSubscription: Subscription;
    private runsRefreshSubscription: Subscription;

    tableColumnsSchema: TableSchema<KeyMap<Run>>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            visible: true,
            width: 140,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Run>>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: row => row.name
        },
        {
            header: this.translate.instant("CREATED_AT"),
            columnDef: "created_at",
            visible: true,
            width: 170,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<Run>(row => row.created_at, "MMM d, y, h:mm a"),
            sortBy: row => new Date(row.created_at).getTime()
        },
        {
            header: this.translate.instant("FROM"),
            columnDef: "from",
            visible: true,
            width: 110,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<Run>(row => row.from, "MMM d, y"),
            sortBy: row => new Date(row.from).getTime()
        },
        {
            header: this.translate.instant("TO"),
            columnDef: "to",
            visible: true,
            width: 110,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<Run>(row => row.to, "MMM d, y"),
            sortBy: row => new Date(row.to).getTime()
        },
        /* Timezone */
        {
            header: this.translate.instant("TIME_ZONE"),
            columnDef: "timezone",
            visible: true,
            component: ZxNgbHighlightComponent,
            width: 240,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Run>>(
                row => this.timeZonePipe.transform(row.timezone, "utc").text,
                row => this.timeZonePipe.transform(row.timezone, "utc").text,
                () => true
            ),
            sortBy: row => this.timeZonePipe.transform(row.timezone, "utc").text
        },
        /* Status */
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Run>>(
                row =>
                    this.titlecasePipe.transform(row.status) +
                    (row.status === "processing"
                        ? ": " + this.decimalPipe.transform(row.path, "1.0-1") + "%"
                        : row.status === "error"
                        ? ": " + row.path
                        : ""),
                row =>
                    this.titlecasePipe.transform(row.status) +
                    (row.status === "processing"
                        ? ": " + this.decimalPipe.transform(row.path, "1.0-1") + "%"
                        : row.status === "error"
                        ? ": " + row.path
                        : ""),
                () => true
            ),
            sortBy: row =>
                this.titlecasePipe.transform(row.status) +
                (row.status === "processing"
                    ? ": " + this.decimalPipe.transform(row.path, "1.0-1") + "%"
                    : row.status === "error"
                    ? ": " + row.path
                    : "")
        },
        /* Action */
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 80,
            visible: true,
            align: "right",
            stickyToLast: true,
            component: ReportHistoryActionsColumnComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<ReportHistoryActionsColumnComponent>,
                row: KeyMap<Run>
            ) => {
                const componentInstance = componentRef.instance;
                componentInstance.run = row;
                componentInstance.report = this.report;
                componentInstance.canEdit = this.canEdit;
            }
        }
    ];

    constructor(
        private rs: ReportsService,
        public sharedService: SharedService,
        private translate: TranslateService,
        private timeZonePipe: TimezonePipe,
        private titlecasePipe: TitleCasePipe,
        private decimalPipe: DecimalPipe
    ) {}

    ngOnInit() {
        this.loading = true;
        this.rs.getReportHistory(this.report);

        this.runsSubscription = this.rs.runs.subscribe(runs => {
            this.runs = runs;
            this.prepTableData();
            if (this.runs) {
                this.loading = false;
            }
        });

        // Start Auto Refresh
        this.startAutoRefresh();
    }

    ngOnDestroy() {
        this.runsSubscription.unsubscribe();
        this.stopAutoRefresh();
    }

    startAutoRefresh() {
        this.runsRefreshSubscription = interval(60000 / 12).subscribe(() => {
            this.refresh();
        });
    }

    stopAutoRefresh() {
        this.runsRefreshSubscription.unsubscribe();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.report) {
            if (changes.report.previousValue && changes.report.currentValue) {
                if (changes.report.previousValue.id !== changes.report.currentValue.id) {
                    this.loading = true;
                    this.runs = [];
                    this.prepTableData();
                    this.stopAutoRefresh();
                    await this.rs.getReportHistory(changes.report.currentValue).toPromise();
                    this.loading = false;
                    this.startAutoRefresh();
                }
            }
        }
    }

    async refresh() {
        await this.rs.getReportHistory(this.report).toPromise();
    }

    get runs$() {
        return this.runsBS$;
    }

    private prepTableData() {
        if (this.runs) {
            this.runsBS$.next(this.runs);
        }
    }
}
