<div class="details-form">
    <div class="form-group row">
        <h5 class="col-12" translate>NAME</h5>
        <div class="col-12">
            <span>{{ report.name }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="report.resourceTags && report.resourceTags.length">
        <h5 class="col-12" translate>TAGS</h5>
        <div class="col-12">
            <span *ngFor="let tag of report.resourceTags; index as i"><span *ngIf="i > 0">, </span>{{ tag.name }}</span>
        </div>
    </div>
    <div class="form-group row">
        <h5 class="col-12" translate>TYPE</h5>
        <div class="col-12">
            <span>{{ report.type | titlecase }}</span>
        </div>
    </div>
    <div class="form-group row">
        <h5 class="col-12" translate>OBJECT_TYPES</h5>
        <div class="col-12 comma-list">
            <span *ngIf="report.feeders">{{ "FEEDERS" | translate }}</span>
            <span *ngIf="report.clusters">{{ "CLUSTERS" | translate }}</span>
            <span *ngIf="report.broadcasters">{{ "BROADCASTERS" | translate }}</span>
            <span *ngIf="report.receivers">{{ "RECEIVERS" | translate }}</span>
            <span *ngIf="report.sources">{{ "SOURCES" | translate }}</span>
            <span *ngIf="report.channels">{{ "CHANNELS" | translate }}</span>
            <span *ngIf="report.targets">{{ "TARGETS" | translate }}</span>
            <span *ngIf="report.options.transcoding">{{ "TRANSCODING" | translate }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="report.breakdown">
        <h5 class="col-12" translate>GRANULARITY</h5>
        <div class="col-12">
            <span *ngIf="report.breakdown === '1h'">{{ "HOUR" | translate }}</span>
            <span *ngIf="report.breakdown === '1d'">{{ "DAY" | translate }}</span>
            <span *ngIf="report.breakdown === '1w'">{{ "WEEK" | translate }}</span>
        </div>
    </div>
    <div class="form-group row">
        <h5 class="col-12" translate>TIME_PERIOD</h5>
        <div class="col-12">
            <span *ngIf="report.period && report.period_param && report.period_param !== '0' && report.period_param !== '1'"
                >{{ report.period_param }} {{ report.period }}</span
            >
            <span *ngIf="report.period && report.period_param && report.period_param === '0'">{{ "CURRENT" | translate }} {{ report.period | singular }}</span>
            <span *ngIf="report.period && report.period_param && report.period_param === '1'">{{ "PREVIOUS" | translate }} {{ report.period | singular }}</span>
            <span *ngIf="report.to && report.from"
                >{{ report.from | date : "MMM d, y" }} {{ "TO" | translate | lowercase }} {{ report.to | date : "MMM d, y" }}</span
            >
        </div>
    </div>
    <div class="form-group row">
        <h5 class="col-12" translate>TIME_ZONE</h5>
        <div class="col-12">
            <span>{{ (report.timezone | timezone : "utc").text }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="report.options && report.options.thresholds && report.options.thresholds.bitrate">
        <h5 class="col-12" translate>BITRATE_THRESHOLD</h5>
        <div class="col-12">
            <span>{{ report.options.thresholds.bitrate / 1000 | number : "1.0-0" }} kbps</span>
        </div>
    </div>
</div>
