import { Component, Input } from "@angular/core";
import { Report } from "../../report";

@Component({
    selector: "app-report-details",
    templateUrl: "./report-details.component.html"
})
export class ReportDetailsComponent {
    @Input() report: Report;
}
