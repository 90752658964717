import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";
import { MatMenuModule } from "@angular/material/menu";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Routes
import { ReportsRouting } from "./reports.routing";

// Components
import { ReportsComponent } from "./reports.component";
import { ReportComponent } from "./report/report.component";
import { ReportFormComponent } from "./report-form/report-form.component";
import { ReportListComponent } from "./report-list/report-list.component";
import { ReportDetailsComponent } from "./report/report-details/report-details.component";
import { ReportHistoryComponent } from "./report/report-history/report-history.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        MatMenuModule,
        NgbModule,
        FontAwesome,
        SharedModule,
        ReportsRouting
    ],
    declarations: [
        ReportsComponent,
        ReportComponent,
        ReportFormComponent,
        ReportListComponent,
        ReportDetailsComponent,
        ReportHistoryComponent
    ]
})
export class ReportsModule {}
