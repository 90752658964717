<app-table-list
    [tableName]="'run-history'"
    [displayTableName]="'RUN_HISTORY' | translate"
    [data]="runs$ | async"
    [(tableSchema)]="tableColumnsSchema"
    [showSelectionCheckbox]="false"
    [showReport]="false"
    [refreshing]="loading"
    [showPagination]="true"
    [showFilter]="false"
    [showColumnsSelection]="false"
    [selectable]="false"
    [hoverable]="false"
    [autoRows]="autoRows"
>
</app-table-list>
