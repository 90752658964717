import { Injectable } from "@angular/core";
import { Observable, Subscriber } from "rxjs";

import { ReportsService } from "../reports.service";
import { Report } from "../report";

@Injectable({
    providedIn: "root"
})
export class ReportListResolverService {
    constructor(private rs: ReportsService) {}

    resolve(): Observable<Report[]> | Observable<never> {
        return new Observable((observe: Subscriber<Report[]>) => {
            this.rs.refreshReports().subscribe((reports: Report[]) => {
                observe.next(reports);
                observe.complete();
            });
        });
    }
}
