import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";

import { Constants } from "../../constants/constants";
import { ReportsComponent } from "./reports.component";
import { ReportComponent } from "./report/report.component";
import { ReportFormComponent } from "./report-form/report-form.component";
import { ReportListComponent } from "./report-list/report-list.component";
import { ReportListResolverService } from "./report-list/report-list-resolver.service";

const reportRoutes: Routes = [
    {
        path: Constants.urls.reports + "/new",
        component: ReportFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_REPORT",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/F4CCLQ"
                }
            ]
        }
    },
    {
        path: Constants.urls.reports + "/:id/:action",
        component: ReportFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.reports,
        component: ReportsComponent,
        canActivateChild: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.REPORTS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/FACCLQ"
                },
                {
                    menu: "HELP_MENU.NEW_REPORT",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/F4CCLQ"
                },
                {
                    menu: "HELP_MENU.GENERATING_REPORTS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/CQCLLQ"
                },
                {
                    menu: "HELP_MENU.VIEWING_AND_DOWNLOADING_REPORTS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/cICGLQ"
                },
                {
                    menu: "HELP_MENU.UNDERSTANDING_REPORT_CONTENT",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/uQAKR"
                }
            ]
        },
        children: [
            {
                path: "",
                component: ReportListComponent,
                resolve: {
                    report: ReportListResolverService
                },
                data: {
                    help: [
                        {
                            menu: "HELP_MENU.REPORTS",
                            link: "https://zixidocumentation.atlassian.net/wiki/x/FACCLQ"
                        },
                        {
                            menu: "HELP_MENU.NEW_REPORT",
                            link: "https://zixidocumentation.atlassian.net/wiki/x/F4CCLQ"
                        },
                        {
                            menu: "HELP_MENU.GENERATING_REPORTS",
                            link: "https://zixidocumentation.atlassian.net/wiki/x/CQCLLQ"
                        },
                        {
                            menu: "HELP_MENU.VIEWING_AND_DOWNLOADING_REPORTS",
                            link: "https://zixidocumentation.atlassian.net/wiki/x/cICGLQ"
                        },
                        {
                            menu: "HELP_MENU.UNDERSTANDING_REPORT_CONTENT",
                            link: "https://zixidocumentation.atlassian.net/wiki/x/uQAKR"
                        }
                    ]
                },
                children: [
                    {
                        path: ":id",
                        component: ReportComponent,
                        data: {
                            help: [
                                {
                                    menu: "HELP_MENU.GENERATING_REPORTS",
                                    link: "https://zixidocumentation.atlassian.net/wiki/x/CQCLLQ"
                                },
                                {
                                    menu: "HELP_MENU.VIEWING_AND_DOWNLOADING_REPORTS",
                                    link: "https://zixidocumentation.atlassian.net/wiki/x/cICGLQ"
                                },
                                {
                                    menu: "HELP_MENU.UNDERSTANDING_REPORT_CONTENT",
                                    link: "https://zixidocumentation.atlassian.net/wiki/x/uQAKR"
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(reportRoutes)],
    exports: [RouterModule]
})
export class ReportsRouting {}
